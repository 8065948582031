import baseComponent from "@/scripts/baseComponent";
import { API_CALL_STATUS } from "@/constant/config";
import usersApi from '@/api/common/users';
import { mapState } from "vuex";
import UserSameIp from "../../views/components/column/UserSameIp.vue";
import ColumnUser from "../../views/components/column/User.vue";
import ColumnBalance from "../../views/components/column/Balance.vue";
import CaretTop from "../../views/components/Icon/CaretTop.vue";
import CaretBottom from "../../views/components/Icon/CaretBottom.vue";
const queryString = require('query-string');

export default {

    extends: baseComponent,

    components: {
        UserSameIp,
        ColumnUser,
        ColumnBalance,
        CaretTop,
        CaretBottom,
    },

    props: {
        element: {
            type: Object,
            default: {}
        },
    },

    data() {
        return {
            isCheckAll: false,
            listData: [],
            pagination: {
                id: null,
                userId: null,
                querySearch: '',
                orderBy: 'A.username',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 20,
                outRowsNumber: 0,
            },
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },

    created() {

    },

    mounted() {
        this.pagination[this.element.type === 1 ? 'userId' : 'id'] = this.element.data;
        this.pagination.orderBy = this.element.type === 1 ? 'A.ID' : 'A.username';
        this.getData();
    },

    methods: {

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData();
                    break;
                case 'refresh-silent':
                    this.getData(param.action);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Get data
        getData(type) {
            this.isCheckAll = false;
            switch (type) {
                case 'refresh-silent':
                    break;
                default:
                    this.feedStatusLoading(API_CALL_STATUS.LOADING);
                    break;
            }
            usersApi[this.element.type === 1 ? 'getUserActivityMatchView' : 'getUserSameIpView'](queryString.stringify(this.pagination))
                .then((res) => {
                    try {
                        let responseData = res.data;
                        let pagingItem = res.data.pagingItem;
                        responseData.data.forEach(element => {
                            element.columnUser = this.mapColumnUser(element);
                        });
                        this.listData = responseData.data;
                        this.pagination.orderBy = pagingItem.orderBy;
                        this.pagination.directionSort = pagingItem.directionSort;
                        this.pagination.pageIndex = pagingItem.pageIndex;
                        this.pagination.pageSize = pagingItem.pageSize;
                        this.pagination.outRowsNumber = pagingItem.outRowsNumber;
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    // location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },

        // Search list
        searchList() {
            this.pagination.status = 0;
            this.pagination.querySearch;
            this.getData();
        },

        // Sort list
        sortList(element) {
            this.pagination.pageIndex = 1;
            this.pagination.orderBy = element;
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            this.getData();
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.pageIndex = element;
            this.getData();
        },

        // Map to component
        mapColumnUser(element) {
            let result = {};
            try {
                result = {
                    username: element.username,
                    registerAt: element.userCreatedAt,
                    region: element.region,
                }
            } catch {
                result = {};
            }
            return result;
        },

        mapColumnBalance(element) {
            let result = {};
            try {
                result = {
                    username: element.username,
                    totalRecharge: element.totalRecharge,
                    balance: element.balance,
                    totalImage: element.totalImage,
                    imageThreshold: element.imageThreshold,
                    totalStorage: element.totalStorage,
                    storageThreshold: element.storageThreshold,
                }
            } catch {
                result = {};
            }
            return result;
        },
    }
} 