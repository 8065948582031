<template>
  <div class="d-flex flex-row align-items-center justify-content-end mt-2">
    <el-tooltip
      effect="light"
      :content="`Active history matches: `"
      placement="top"
    >
      <div
        @click="getUserActivityMatchView()"
        :style="`${
          element.countUserSameIp === 0 || element.countUserSameIp === '...'
            ? 'filter: grayscale(100%); opacity: 0.4;'
            : 'cursor: pointer;'
        };`"
        style="color: darkorange; font-size: 0.85rem"
        class="d-flex flex-row justify-content-end align-items-center"
      >
        <div>
          <i style="font-size: 0.75rem" class="el-icon-copy-document"></i>
        </div>
        <div class="ml-1">
          <span style="color: red; font-size: 1.1rem">
            {{ element.countUserSameIp }}
          </span>
          <span class="ml-1">user</span>
        </div>
      </div>
    </el-tooltip>
    <el-tooltip
      effect="light"
      :content="`Number IP address used: `"
      placement="top"
    >
      <div
        :style="`filter: ${
          element.countNumberIp === 0 || element.countNumberIp === '...'
            ? 'grayscale(100%); opacity: 0.4'
            : 'none'
        }`"
        style="color: #9e9e9e; cursor: pointer; font-size: 0.85rem"
        class="d-flex flex-row justify-content-end align-items-center ml-2"
      >
        <div>
          <i style="font-size: 0.75rem" class="el-icon-place"></i>
        </div>
        <div class="ml-1">
          <span style="color: #007bff; font-size: 1.1rem">
            {{ element.countNumberIp }}
          </span>
          <span class="ml-1">IP</span>
        </div>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import EventBus from "@/event/EventBus";
import usersApi from "@/api/common/users";
export default {
  data() {
    return {
      element: {
        countUserSameIp: "...",
        countNumberIp: "...",
      },
    };
  },
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  //watch: {
  //    userId(newVal) {
  //        if (this.userId !== null) {
  //            this.getAdditionalInfoCountSameIp();
  //        }
  //    },
  //},
  mounted() {
    this.getAdditionalInfoCountSameIp();
  },
  methods: {
    //User activity match view
    getUserActivityMatchView() {
      if (this.element.countUserSameIp > 0) {
        EventBus.$emit("openPopup", {
          type: "user-same-ip-list",
          data: {
            type: 1,
            data: this.userId,
          },
        });
      }
    },

    getAdditionalInfoCountSameIp() {
      if (this.userId !== null) {
        usersApi
          .getAdditionalInfoCountSameIp(this.userId)
          .then((response) => {
            if (response.data && response.data.result === 0) {
              //console.log("getAdditionalInfoCountSameIp", response.data.data);
              this.$set(this, "element", response.data.data);
            } else {
              if (
                  response.data && response.data.message !== null &&
                response.data.message !== ""
              ) {
                console.error(response.data.message || this.$lang.common.error);
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>