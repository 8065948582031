var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row align-items-center justify-content-end mt-2"},[_c('el-tooltip',{attrs:{"effect":"light","content":`Active history matches: `,"placement":"top"}},[_c('div',{staticClass:"d-flex flex-row justify-content-end align-items-center",staticStyle:{"color":"darkorange","font-size":"0.85rem"},style:(`${
        _vm.element.countUserSameIp === 0 || _vm.element.countUserSameIp === '...'
          ? 'filter: grayscale(100%); opacity: 0.4;'
          : 'cursor: pointer;'
      };`),on:{"click":function($event){return _vm.getUserActivityMatchView()}}},[_c('div',[_c('i',{staticClass:"el-icon-copy-document",staticStyle:{"font-size":"0.75rem"}})]),_c('div',{staticClass:"ml-1"},[_c('span',{staticStyle:{"color":"red","font-size":"1.1rem"}},[_vm._v(" "+_vm._s(_vm.element.countUserSameIp)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("user")])])])]),_c('el-tooltip',{attrs:{"effect":"light","content":`Number IP address used: `,"placement":"top"}},[_c('div',{staticClass:"d-flex flex-row justify-content-end align-items-center ml-2",staticStyle:{"color":"#9e9e9e","cursor":"pointer","font-size":"0.85rem"},style:(`filter: ${
        _vm.element.countNumberIp === 0 || _vm.element.countNumberIp === '...'
          ? 'grayscale(100%); opacity: 0.4'
          : 'none'
      }`)},[_c('div',[_c('i',{staticClass:"el-icon-place",staticStyle:{"font-size":"0.75rem"}})]),_c('div',{staticClass:"ml-1"},[_c('span',{staticStyle:{"color":"#007bff","font-size":"1.1rem"}},[_vm._v(" "+_vm._s(_vm.element.countNumberIp)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("IP")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }